var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-10",attrs:{"fluid":""}},[_c('v-popup-loading',{attrs:{"isLoading":_vm.isLoading}}),_c('v-alert-error',{attrs:{"t-data":"alert-error","show":_vm.errorMessage.length > 0,"msg":_vm.errorMessage,"close":_vm.closeAlert}}),_c('v-snackbar',{staticClass:"localize-snackbar",attrs:{"color":"success200Color","top":"","centered":""},model:{value:(_vm.isUpdateSuccess),callback:function ($$v) {_vm.isUpdateSuccess=$$v},expression:"isUpdateSuccess"}},[_c('div',{staticClass:"d-flex flex-row align-center mt-0"},[_c('v-img',{staticClass:"mt-0 mr-2",class:{
          'snackbar-image': _vm.isUpdateSuccess
        },attrs:{"src":"/images/snackbar-success.png","aspect-ratio":"1","snackbar-image":""}}),_c('p',{class:{
          'snackbar-message sub-body-regular': _vm.isUpdateSuccess,
          'mb-0': _vm.isUpdateSuccess
        }},[_vm._v(" Update Localize successfully. ")])],1)]),_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"px-0",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('h3',{staticClass:"pageHeadline",attrs:{"t-data":"page-headline"}},[_vm._v("Localize")]),_c('v-btn',{staticClass:"white-text-button button-bold",attrs:{"id":"add-btn","large":"","color":"primary600Color","t-data":"update-btn"},on:{"click":_vm.updateLocalize}},[_vm._v(" Update Localize ")])],1)],1)],1),_c('v-card',[_c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1 custom-table localize-table",staticStyle:{"width":"100%"},attrs:{"t-data":"data-table","headers":_vm.headers,"items":_vm.localizeList,"loading":_vm.isLoading,"options":_vm.options,"items-per-page":_vm.pageSize,"server-items-length":_vm.total,"footer-props":{'items-per-page-options': _vm.itemsPerPage},"item-key":"id","sort-desc":true,"must-sort":false,"fixed-header":"","height":_vm.windowSize.y - _vm.heightOptions.header - _vm.heightOptions.tableFooter},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.create_date",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(!!item.create_date ? _vm.dateAndTimeFmt(item.create_date) : ''))]}},{key:"item.update_by_user",fn:function(ref){
        var item = ref.item;
return [_c('username-status-text',{attrs:{"user":item.update_by_user}})]}}])})],1),_c('div',{staticClass:"table-footer-prepend",attrs:{"t-data":"total-result-content"}},[_c('div',{staticClass:"vertical-center pl-4"},[_c('b',[_vm._v(_vm._s(_vm.total))]),_vm._v(" Search results ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }