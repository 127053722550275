<template>
  <v-container class="px-10" fluid>
    <v-popup-loading :isLoading="isLoading" />
    <v-alert-error
      t-data="alert-error"
      :show="errorMessage.length > 0"
      :msg="errorMessage"
      :close="closeAlert"
    ></v-alert-error>
    <v-snackbar
      class="localize-snackbar"
      v-model="isUpdateSuccess"
      color="success200Color"
      top
      centered
    >
      <div class="d-flex flex-row align-center mt-0">
        <v-img
          src="/images/snackbar-success.png"
          aspect-ratio="1"
          snackbar-image
          class="mt-0 mr-2"
          :class="{
            'snackbar-image': isUpdateSuccess
          }"
        ></v-img>
        <p
          :class="{
            'snackbar-message sub-body-regular': isUpdateSuccess,
            'mb-0': isUpdateSuccess
          }"
        >
          Update Localize successfully.
        </p>
      </div>
    </v-snackbar>
    <!-- Breadcrumbs -->
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
          <h3 class="pageHeadline" t-data="page-headline">Localize</h3>
          <v-btn
            id="add-btn"
            large
            color="primary600Color"
            @click="updateLocalize"
            class="white-text-button button-bold"
            t-data="update-btn"
          >
            Update Localize
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-card>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          t-data="data-table"
          :headers="headers"
          :items="localizeList"
          :loading="isLoading"
          :options.sync="options"
          :items-per-page="pageSize"
          :server-items-length="total"
          :footer-props="{'items-per-page-options': itemsPerPage}"
          item-key="id"
          :sort-desc="true"
          :must-sort="false"
          class="elevation-1 custom-table localize-table"
          fixed-header
          style="width: 100%"
          :height="
            windowSize.y - heightOptions.header - heightOptions.tableFooter
          "
        >
          <template v-slot:item.create_date="{item}">{{ !!item.create_date ? dateAndTimeFmt(item.create_date) : '' }}</template>
          <template v-slot:item.update_by_user="{item}"><username-status-text :user="item.update_by_user" /></template>
        </v-data-table>
      </v-layout>
      <div class="table-footer-prepend" t-data="total-result-content">
        <div class="vertical-center pl-4">
          <b>{{ total }}</b> Search results
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import AlertError from '@/components/AlertError'
import PopupLoading from '@/components/PopupLoading'
import {convertDate} from '@/helper/helper'
import UsernameStatusText from '@/components/UsernameStatusText'

export default {
  components: {
    'v-alert-error': AlertError,
    'v-popup-loading': PopupLoading,
    UsernameStatusText,
  },
  data() {
    return {
      heightOptions: {
        header: 216,
        tableFooter: 59,
      },
      windowSize: {
        x: 0,
        y: 0,
      },
      isUpdateSuccess: false,
      breadcrumbs: [
        {
          text: 'App Config',
          exact: true,
          href: '/'
        },
        {
          text: 'Localize',
          disabled: true,
          link: true,
          href: '/'
        }
      ],
      options: {},
      page: 1,
      headers: [
        {text: 'No.', align: 'left', value: 'id', sortable: false, width: 52},
        {
          text: 'Version',
          align: 'left',
          value: 'build_version',
          sortable: true,
          width: 450
        },
        {
          text: 'Updated Date/Time',
          align: 'left',
          value: 'create_date',
          sortable: true,
          width: 200
        },
        {
          text: 'Updated By',
          align: 'left',
          value: 'update_by_user',
          sortable: true,
          width: 250
        }
      ],
      pageSize: 25,
      itemsPerPage: [25, 50, 100]
    }
  },
  computed: {
    ...mapState('localize', [
      'isLoading',
      'errorMessage',
      'localizeList',
      'total'
    ])
  },
  async created() {
    await this.fetch()
  },
  beforeRouteLeave(to, from, next) {
    this.resetList()
    next()
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    closeAlert() {
      this.resetErrorMsg()
    },
    async updateLocalize() {
      this.isUpdateSuccess = await this.update()
      await this.fetch()
    },
    dateAndTimeFmt: date => convertDate(date),
    ...mapActions('localize', [
      'fetch',
      'update',
      'resetErrorMsg',
      'resetList',
      'setFieldSort',
      'setPage',
      'setPageSize',
      'setSort'
    ])
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        let selectedSortBy = sortBy[0]
        if (sortBy.length > 0) {
          selectedSortBy = sortBy[0] === 'update_by_user' ? 'update_by_user.name' : sortBy[0]
        }
        this.setPage(page)
        this.setFieldSort(selectedSortBy)
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.localize-snackbar > .v-snack__wrapper {
  min-width: 250px;
}
.localize-table.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 82px;
}

</style>
